import React from "react";
import { Link } from "react-router-dom";
import { Button, Container, Paper, Typography } from "@mui/material";
import { FOOTER_HEIGHT } from "../constants";
import { FlexRow } from "./FlexRow";
import FooterIcon from "../assets/pngs/footer-logo.png";
import { ReactComponent as TwitterIcon } from "../assets/svgs/twitter-black.svg";

const LeftLinks: any = [
  {
    title: "About us",
    link: "/",
  },
  {
    title: "Careers",
    link: "/",
  },
  {
    title: "Privacy Policy",
    link: "/",
  },
  {
    title: "Terms of Service",
    link: "/",
  },
];

const RightLinks: any = [
  {
    title: "@bolt_zap",
    link: "https://twitter.com/bolt_zap",
  },
  {
    title: "@boltplus_tv",
    link: "https://twitter.com/boltplus_tv",
  },
  {
    title: "@boltx_wallet",
    link: "https://twitter.com/boltx_wallet",
  },
];

export const Footer = () => {
  return (
    <Paper sx={{height: `${FOOTER_HEIGHT}px`, backgroundColor: "#DCDCDC"}} component="footer" square variant="outlined">
      <Container sx={{ "&.MuiContainer-maxWidthLg": { maxWidth: 1360 } }}>
        <FlexRow sx={{ width: "100%", mt: 2, mb: 1.4 }}>
          <img
            src={FooterIcon}
            alt="Footer Logo"
            width="103px"
            height="auto"
          />
        </FlexRow>
        <FlexRow sx={{justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <FlexRow gap={3}>
            {LeftLinks.map((item: any) => (
              <Button key={item.title} component={Link} variant="text" to={item.link} sx={{
                "&:hover": { backgroundColor: "transparent" }
              }}>
                <Typography sx={{ color: "#53515E", fontSize: 14, lineHeight: 1 }}>
                  {item.title}
                </Typography>
              </Button>
            ))}
          </FlexRow>
          <FlexRow gap={2}>
            {RightLinks.map((item: any) => (
              <Button key={item.title} variant="text" href={item.link} sx={{
                "&:hover": { backgroundColor: "transparent" }
              }}>
                <TwitterIcon />
                <Typography sx={{ color: "#333333", fontSize: 16, lineHeight: 1, ml: 1 }}>
                  {item.title}
                </Typography>
              </Button>
            ))}
          </FlexRow>
        </FlexRow>
      </Container>
    </Paper>
  );
};