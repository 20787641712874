import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as GoogleIcon } from "../../assets/svgs/google.svg";
import { ReactComponent as AppleIcon } from "../../assets/svgs/apple.svg";

export const LoginPage = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center", height: "100%" }}
    >
      <Typography sx={{ my: 10, fontSize: 128, lineHeight: 1 }}>👋</Typography>
      <Typography sx={{ fontSize: 40, fontWeight: 600, mb: 6.6 }}>Log in</Typography>
      <Button
        variant="contained"
        component={Link}
        to="/create-username"
        sx={{
          backgroundColor: "#F2F2F2",
          px: 6,
          py: 3,
          mb: 4,
          minWidth: "370px",
          "&:hover": { backgroundColor: "#F2F2F2" }
        }}
      >
        <GoogleIcon/>
        <Typography sx={{ color: "#333333", fontSize: 25, lineHeight: 1, ml: 3, fontWeight: 600 }}>
          Login with Google
        </Typography>
      </Button>
      <Button
        variant="contained"
        component={Link}
        to="/create-username"
        sx={{
          backgroundColor: "#333333",
          px: 6,
          py: 3,
          mb: 11,
          minWidth: "370px",
          "&:hover": { backgroundColor: "#333333" }
        }}
      >
        <AppleIcon/>
        <Typography sx={{ color: "#F2F2F2", fontSize: 25, lineHeight: 1, ml: 3, fontWeight: 600 }}>
          Login with Apple
        </Typography>
      </Button>
      <Button
        variant="contained"
        component={Link}
        to="/sign-up"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          textDecoration: "underline",
          px: 6,
          py: 3,
          "&:hover": { backgroundColor: "transparent", boxShadow: "none", textDecoration: "underline" }
        }}
      >
        <Typography sx={{ color: "#FFFFFF", fontSize: 25, lineHeight: 1 }}>
          Need an account?   Sign up
        </Typography>
      </Button>
    </Container>
  );
};
