import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { FOOTER_HEIGHT } from "../../constants";
import { Footer } from "../Footer";

export const PrivateLayout = () => {
  return (
    <Box sx={{ height: `calc(100vh - ${FOOTER_HEIGHT}px)` }}>
      <Outlet />
      <Footer/>
    </Box>
  );
};
