import { combineReducers } from "redux";
import { History } from "history";
import { connectRouter } from "connected-react-router";

import { RootState } from "../types";
import templateReducer from "./template/reducer";

export default function rootReducer(history: History) {
  return combineReducers<RootState>({
    template: templateReducer,
    router: connectRouter(history),
  });
}
