import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Button, Container, Typography } from "@mui/material";
import { FlexRow } from "./FlexRow";
import LogoIcon from "../assets/pngs/logo.png";

export const Navbar = () => {
  return (
    <AppBar
      sx={{
        backgroundColor: "transparent",
        backgroundImage: "none",
        boxShadow: "none",
        pt: 7,
      }}
      position="fixed"
    >
      <Container sx={{ "&.MuiContainer-maxWidthLg": { maxWidth: 1360 } }}>
        <FlexRow sx={{justifyContent: "space-between", alignItems: "center", width: "100%" }}>
          <FlexRow>
            <Link to="/">
              <img
                src={LogoIcon}
                alt="Footer Logo"
                width="217px"
                height="auto"
              />
            </Link>
          </FlexRow>
          <FlexRow>
            <Button
              variant="contained"
              component={Link}
              to="/login"
              sx={{
                backgroundColor: "#F2F2F2",
                boxShadow: "0px 0px 50px rgba(242, 242, 242, 0.5)",
                px: 6,
                py: 3,
                "&:hover": { backgroundColor: "#F2F2F2", boxShadow: "0px 0px 50px rgba(242, 242, 242, 0.5)" }
              }}
            >
              <Typography sx={{ color: "#333333", fontSize: 25, lineHeight: 1 }}>
                Log in
              </Typography>
            </Button>
            <Button
              variant="contained"
              component={Link}
              to="/sign-up"
              sx={{
                backgroundColor: "transparent",
                boxShadow: "none",
                textDecoration: "underline",
                px: 6,
                py: 3,
                ml: 2,
                "&:hover": { backgroundColor: "transparent", boxShadow: "none", textDecoration: "underline" }
              }}
            >
              <Typography sx={{ color: "#FFFFFF", fontSize: 25, lineHeight: 1 }}>
                Sign up
              </Typography>
            </Button>
          </FlexRow>
        </FlexRow>
      </Container>
    </AppBar>
  );
};