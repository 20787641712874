import React from "react";
import { Route, Routes } from "react-router-dom";
import { BackLayout, ModalLayout, PrivateLayout, PublicLayout } from "../components";
import { LandingPage } from "../pages";
import { HomePage } from "../pages/home";
import { LoginPage } from "../pages/login";
import { SignUpPage } from "../pages/sign-up";
import { CreateUsernamePage } from "../pages/create-username";
import { ClaimUsernamePage } from "../pages/claim-username";

export const RootRouter = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path="/*" element={<LandingPage />} />
      </Route>
      <Route element={<ModalLayout />}>
        <Route path="/login" element={<LoginPage />} />
      </Route>
      <Route element={<ModalLayout />}>
        <Route path="/sign-up" element={<SignUpPage />} />
      </Route>
      <Route element={<ModalLayout />}>
        <Route path="/create-username" element={<CreateUsernamePage />} />
      </Route>
      <Route element={<BackLayout />}>
        <Route path="/claim-username" element={<ClaimUsernamePage />} />
      </Route>
      <Route element={<PrivateLayout />}>
        <Route path="/home" element={<HomePage />} />
      </Route>
    </Routes>
  );
};
