import { Box, IconButton } from "@mui/material";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Footer } from "../Footer";
import { FOOTER_HEIGHT } from "../../constants";
import { ReactComponent as BackArrowIcon } from "../../assets/svgs/back-arrow.svg";

export const BackLayout = () => {
  const navigate = useNavigate();
  return (
    <Box sx={{ height: `calc(100vh - ${FOOTER_HEIGHT}px)` }}>
      <IconButton
        onClick={() => navigate(-1)}
        aria-label="back"
        sx={{
          position: "absolute",
          background: "linear-gradient(312.88deg, rgba(106, 93, 171, 0.1) 9.9%, rgba(142, 126, 209, 0.1) 91.06%)",
          borderRadius: "25px",
          p: 3.4,
          top: "63px",
          left: "80px"
        }}
      >
        <BackArrowIcon />
      </IconButton>
      <Outlet />
      <Footer />
    </Box>
  );
};
