import React from "react";
import { Container, Typography } from "@mui/material";

export const LandingPage = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}
    >
      <Typography variant="h4">BoltZap Landing Page</Typography>
    </Container>
  );
};
