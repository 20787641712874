import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../Footer";
import { Navbar } from "../Navbar";
import { FOOTER_HEIGHT } from "../../constants";

export const PublicLayout = () => {
  return (
    <Box sx={{ height: `calc(100vh - ${FOOTER_HEIGHT}px)` }}>
      <Navbar />
      <Outlet />
      <Footer />
    </Box>
  );
};
