import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { PersistGate } from "redux-persist/integration/react";

import { RootRouter } from "./routes";
import { store, persistor } from "./store";
import { ThemeContext } from "./hooks/themeContext";
import { darkTheme, lightTheme } from "./theme";

function App() {
  const [rootStore, setRootStore] = React.useState<any | undefined>(undefined);
  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(() => (mode === "dark" ? darkTheme : lightTheme), [mode]);

  React.useEffect(() => {
    const initStore = async () => {
      setRootStore({ store, persistor });
    };
    initStore();
  }, []);

  if (!rootStore) {
    return null;
  }

  return (
    <Provider store={rootStore.store}>
      <PersistGate loading={null} persistor={rootStore.persistor}>
        <ThemeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <CssBaseline />
              <RootRouter />
            </BrowserRouter>
          </ThemeProvider>
        </ThemeContext.Provider>
      </PersistGate>
    </Provider>
  );
}

export default App;
