import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Navigate, useLocation } from "react-router-dom";
import { FlexCol, FlexRow } from "../../components";
import { ReactComponent as BoltXIcon } from "../../assets/svgs/boltx.svg";
import { ReactComponent as TrastWalletIcon } from "../../assets/svgs/trast-wallet.svg";

export const ClaimUsernamePage = () => {
  const location = useLocation();

  if (!location?.state?.username) {
    return <Navigate to="/create-username" />;
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%"
      }}
    >
      <FlexCol sx={{ alignItems: "center" }}>
        <FlexRow sx={{ my: 10 }}>
          <Typography sx={{ fontSize: 40, lineHeight: 1 }}>Create your</Typography>
          <Typography sx={{
            fontSize: 40,
            lineHeight: 1,
            fontStyle: "italic",
            fontWeight: 600,
            ml: 1
          }}>Username!</Typography>
        </FlexRow>
        <FlexRow sx={{ alignItems: "center" }}>
          <Typography sx={{
            fontSize: 50,
            fontWeight: 600,
            lineHeight: 1,
            marginRight: 2,
            padding: "27px",
            borderRadius: "25px",
            background: "linear-gradient(312.88deg, rgba(106, 93, 171, 0.42) 9.9%, #8E7ED1 91.06%)"
          }}>{location.state.username}</Typography>
          <Typography sx={{ fontSize: 50, lineHeight: 1, fontWeight: 450 }}>.matters</Typography>
        </FlexRow>
        <FlexRow sx={{ mt: 10, mb: 7 }}>
          <Typography sx={{
            fontSize: 24,
            lineHeight: 1.3,
            fontWeight: 600,
            mr: 1
          }}>
            Click the Button
          </Typography>
          <Typography sx={{ fontSize: 24, lineHeight: 1.3 }}> to connect BoltX Wallet </Typography>
        </FlexRow>
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%), #9B51E0",
            boxShadow: "0px 0px 50px rgba(101, 53, 233, 0.5)",
            px: 9,
            py: 3,
            mb: 5,
            "&:hover": {
              background: "linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), linear-gradient(93.56deg, #6535E9 4.6%, #4E33E9 96.96%), #9B51E0",
              boxShadow: "0px 0px 50px rgba(101, 53, 233, 0.5)"
            }
          }}
        >
          <Typography sx={{ color: "#FFFFFF", fontSize: 25, lineHeight: 1, fontWeight: 600, mr: 2 }}>
            Open
          </Typography>
          <BoltXIcon/>
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            textDecoration: "underline",
            px: 6,
            py: 3,
            mb: 2,
            "&:hover": { backgroundColor: "transparent", boxShadow: "none", textDecoration: "underline" }
          }}
        >
          <Typography sx={{ color: "#FFFFFF", fontSize: 25, lineHeight: 1, fontWeight: 600 }}>
            No Wallet? Click here
          </Typography>
        </Button>
        <Typography sx={{ color: "#FFFFFF", fontSize: 25, lineHeight: 1, mb: 2.5 }}>
          or
        </Typography>
        <Button
          variant="contained"
          sx={{
            background: "linear-gradient(312.88deg, rgba(106, 93, 171, 0.21) 9.9%, rgba(142, 126, 209, 0.5) 91.06%)",
            boxShadow: "none",
            borderRadius: "15px",
            px: 6,
            py: 2,
            "&:hover": {
              background: "linear-gradient(312.88deg, rgba(106, 93, 171, 0.21) 9.9%, rgba(142, 126, 209, 0.5) 91.06%)",
              boxShadow: "none"
            }
          }}
        >
          <Typography sx={{ color: "#FFFFFF", fontSize: 25, lineHeight: 1, fontWeight: 600, mr: 2 }}>
            Use
          </Typography>
          <TrastWalletIcon/>
        </Button>
      </FlexCol>
    </Container>
  );
};
