import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FlexCol, FlexRow } from "../../components";

export const HomePage = () => {
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}
    >
      <Typography variant="h4">BoltZap Home Page</Typography>
      <FlexCol sx={{ mt: 4 }} gap={2}>
        <Button variant="contained" component={Link} to="/">
          <Typography>Go Back</Typography>
        </Button>
      </FlexCol>
    </Container>
  );
};
