import { Box, IconButton } from "@mui/material";
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { Footer } from "../Footer";
import { FOOTER_HEIGHT } from "../../constants";
import { ReactComponent as CloseIcon } from "../../assets/svgs/close.svg";

export const ModalLayout = () => {
  return (
    <Box sx={{ height: `calc(100vh - ${FOOTER_HEIGHT}px)` }}>
      <IconButton
        component={Link}
        to="/"
        aria-label="close"
        sx={{
          position: "absolute",
          background: "linear-gradient(312.88deg, rgba(106, 93, 171, 0.1) 9.9%, rgba(142, 126, 209, 0.1) 91.06%)",
          borderRadius: "25px",
          p: 3.4,
          top: "63px",
          left: "80px"
        }}
      >
        <CloseIcon />
      </IconButton>
      <Outlet />
      <Footer />
    </Box>
  );
};
