import React from "react";
import { Button, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FlexCol, FlexRow } from "../../components";

export const CreateUsernamePage = () => {
  const [username, setUsername] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const input = React.useCallback((inputElement: any) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%"
      }}
    >
      <FlexCol sx={{ alignItems: "center" }}>
        <FlexRow>
          <Typography sx={{ my: 10, fontSize: 40, lineHeight: 1 }}>Create your</Typography>
          <Typography sx={{
            my: 10,
            fontSize: 40,
            lineHeight: 1,
            fontStyle: "italic",
            fontWeight: 600,
            ml: 1
          }}>Username!</Typography>
        </FlexRow>
        <FlexRow sx={{ alignItems: "center" }}>
          <input onChange={handleChange} ref={input} value={username} style={{
            width: `calc(${username.length + 2}ch)`, fontSize: 50,
            fontWeight: 600,
            lineHeight: 1,
            marginRight: 20,
            padding: 23,
            borderRadius: 25,
            color: "#FFFFFF",
            background: "linear-gradient(312.88deg, rgba(106, 93, 171, 0.42) 9.9%, #8E7ED1 91.06%)",
            border: "none",
            outline: "none",
          }} />
          <Typography sx={{ fontSize: 50, lineHeight: 1, fontWeight: 450 }}>.matters</Typography>
        </FlexRow>
      </FlexCol>
      <Button
        variant="contained"
        component={Link}
        to="/claim-username"
        state={{username}}
        sx={{
          backgroundColor: "#AE79DF",
          boxShadow: "0px 0px 50px rgba(155, 81, 224, 0.5)",
          px: 6,
          py: 3,
          mb: 7,
          "&:hover": { backgroundColor: "#9B51E0", boxShadow: "0px 0px 50px rgba(155, 81, 224, 0.5)" }
        }}
      >
        <Typography sx={{ color: "#F2F2F2", fontSize: 25, lineHeight: 1, fontWeight: 600 }}>
          Mint Username
        </Typography>
      </Button>
    </Container>
  );
};
